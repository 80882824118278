.box_masked {
    --mask: radial-gradient(60px at 0 100%, #0000 98%, #000) 0 100%/100% 100% no-repeat;
    -webkit-mask: var(--mask);
    mask: var(--mask);
}

.swipper {
    transition: 0.3s all ease-in-out;
    height: 100%;
    overflow: hidden;
}

.swipper-wrapper {
    display: flex;
    flex-shrink: 0;
    height: 100%;
}

.swipper-container {
    overflow-y: auto;
    width: 541px;
    overflow-x: hidden;
    margin-left: 0;
}
.swipper-container_2 {
    overflow-y: auto;
    width: 601px;
    overflow-x: hidden;
    margin-left: 0;
}

::-webkit-scrollbar {
    /*width: 2px;*/
}
.name_author_tag{
    color: #003594;
}