.root {
    /*background-color: #f4f6f8;*/
    display: flex;
    height: 100%;
    /* overflow: hidden; */
    width: 100%;
    background: '#F8FAFF';
}

.wrapper {
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
    padding-top: 54px;
}

.contentContainer {
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
}

.content {
    flex: 1 1 auto;
    height: 100%;
    overflow: auto;
}

.content>div {
    height: 100%;
}

.blur {
    position: fixed;
    inset: 0;
    z-index: 1000;
    filter: blur(1px);
    background-color: #000;
    /* backdrop-filter: blur(1px); */
    transition: filter 0.3s ease-in
}

.versionning_close_btn {
    position: fixed;
    bottom: 15px;
    right: 10px;
    border-radius: 30px;
    height: 25px;
    width: 45px;
    font-size: 8px;
    transition: width 0.3s ease-in, height 0.3s ease-in;
    color: white;
    background-color: #0C1732;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.versionning_open_btn {
    position: fixed;
    bottom: 15px;
    right: 10px;
    border-radius: 30px;
    height: 40px;
    width: 310px;
    padding: 5px 15px 5px 15px;
    transition: width 0.3s ease-in, height 0.3s ease-out;
    color: white;
    background-color: #003594;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.content_1 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.content_2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: #fff;
    font-size: 12px;
}

.versionning_content {
    height: 400px;
    min-width: 310px;
    border-radius: 30px;
    align-content: flex-start;
    display: flex;
    flex-direction: column;
}

.version_head {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    /* height: 20px; */
}
*{
    font-family: PKGlobalSans !important;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}