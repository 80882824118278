.white_input input:-webkit-autofill {
    color: #FFFFFF !important;
}

.white_input input:-webkit-autofill,
.white_input input:-webkit-autofill:hover,
.white_input input:-webkit-autofill:focus,
.white_input input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #003594 inset !important;
    -webkit-text-fill-color: #FFFFFF !important;
}

@font-face {
    font-family: 'PKGlobalSans';
    src: local('PKGlobalSans'),url("./fonts/PKFGlobalSans-Regular.otf") format('opentype');
}
@font-face {
    font-family: 'PKGlobalSansBold';
    src: local('PKGlobalSans'),url("./fonts/PKFGlobalSans-Regular.otf") format('opentype');
}

