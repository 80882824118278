.button {
    position: fixed;
    right: 5px;
    height: 50px;
    background-color: #003594;
    color: white;
    font-weight: 600;
    top: calc(55%);
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: #353030 1px 1px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    min-width: 105px;
    transition: all 200ms;
    opacity: 0.3;
    z-index: 10000;
}